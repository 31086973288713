<template>
  <div role="group" class="mt-5">
    <b-row class="mb-4 align-items-center text-align-end">
      <b-col cols="5"></b-col>
      <b-col>
        <b-input-group size="md">
          <b-form-input
            id="filter-input"
            v-model="filterUsers"
            type="search"
            class="custom-input"
            :placeholder="this.$i18n.t('admin.users.placeholderSearch')"
          >
          </b-form-input>
        </b-input-group>
        <b-icon
          icon="search"
          aria-hidden="true"
          flip-h
          class="input__search__icon"
        ></b-icon>
      </b-col>
      <b-col>
        <b-dropdown
          id="dropdown-form"
          menu-class="form-user"
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          ref="dropdown"
          class="m-2"
          v-if="validationDropdown"
        >
          <template #button-content>
            <b-button
              variant="outline-primary"
              class="button btn-blue-dark ml-0"
            >
              <span v-if="selectedUser.length"
                >{{ $t("admin.users.editUser") }} +</span
              >
              <span v-else>{{ $t("admin.users.createUser") }} +</span>
            </b-button>
          </template>
          <b-dropdown-text class="my-3 text-header-user">
            <b-icon icon="person-plus" aria-hidden="true"></b-icon>
            <span v-if="selectedUser.length">
              {{ $t("admin.users.editUser") }}</span
            >
            <span v-else>{{ $t("admin.users.newUser") }}</span>
          </b-dropdown-text>
          <b-dropdown-form>
            <b-form-input
              id="dropdown-form-name"
              class="form-input-style"
              size="sm"
              :placeholder="this.$i18n.t('admin.users.fullName')"
              :state="validationName"
              v-model="inputFullName"
            ></b-form-input>

            <b-form-input
              id="dropdown-form-email"
              class="form-input-style"
              size="sm"
              placeholder="email@example.com"
              :state="validationEmail"
              v-model="inputEmail"
              type="email"
              :disabled="isEdit"
            ></b-form-input>
            <b-form-select
              v-model="selectedRol"
              :options="optionsRol"
              size="sm"
              class="mt-3 select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled class="m-3">{{
                  $t("admin.users.role")
                }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="selectedStatus"
              :options="optionsStatus"
              size="sm"
              class="mt-3 select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("admin.users.status")
                }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="genericSelected"
              :options="optionsGeneric"
              size="sm"
              class="mt-3 select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("admin.users.generic")
                }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="countrySelected"
              :options="optionsContry"
              size="sm"
              class="mt-3 select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("admin.users.country")
                }}</b-form-select-option>
              </template>
            </b-form-select>

            <b-col class="justify-content-end buttons-dropdown">
              <b-button class="btn btn-blue-dark-outline ml-0" @click="cancel">
                {{ $t("admin.users.cancel") }}
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                @click="handleEditUser"
                :disabled="isLoading || !isValid"
                v-if="selectedUser.length"
              >
                {{ $t("admin.users.save") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                @click="handleCreateUser"
                :disabled="isLoading || !isValid"
                v-else
              >
                {{ $t("admin.users.create") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
            </b-col>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- TABLE USERS-->
    <template v-if="configRol.administrador.pestana.users.acciones.listar">
      <b-table
        :items="itemsUsers"
        :fields="fieldsUsers"
        :filter="filterUsers"
        :filter-included-fields="filterOn"
        stacked="md"
        show-empty
        hover
        table-variant="light"
        class="tableFirst"
        sort-icon-left
        select-mode="single"
        selectable
        borderless
        tbody-tr-class="table-users"
        @row-selected="onRowSelectedUser"
      >
        <template #cell(nombre)="row">
          <b>{{ row.value }}</b>
        </template>
        <template #cell(roles)="row">
          {{ row.value[0] && row.value[0].name }}
        </template>
        <template #cell(actions)="{ rowSelected }">
          <template v-if="rowSelected">
            <div>
              <span aria-hidden="true"
                ><b-icon icon="check-square" aria-hidden="true"></b-icon
              ></span>
              <span class="sr-only">Selected</span>
            </div>
          </template>
          <template v-else>
            <span aria-hidden="true"
              ><b-icon icon="square" aria-hidden="true"></b-icon
            ></span>
            <span class="sr-only">Not Selected</span>
          </template>
        </template>

        <template #cell(is_user_active)="row">
          <b v-if="row.value" class="text-success">Activo</b>
          <b v-else class="text-secondary">Inactivo</b>
        </template>
      </b-table>
    </template>
    <template v-else>
      <b-alert show dismissible>
        <p>
           {{ $t("permissions.msgPermissionsUsers") }}
        </p>
      </b-alert>
    </template>
  </div>
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import { AlertForm, ICON } from "../../helpers/Alert";
import { ApiRequest } from '../../helpers/ApiRequest';
export default {
  name: "users",
  components: {},
  data: () => ({
    name: "",
    email: "",
    itemsUsers: [],
    filterUsers: null,
    filterOn: [],
    selectedRol: null,
    selectedStatus: null,
    inputFullName: "",
    inputEmail: "",
    genericSelected: null,
    countrySelected: null,
    selectedUser: [],
    optionsRol: [],
    optionsStatus: [
      { text: "Activo", value: true },
      { text: "Inactivo", value: false },
    ],
    optionsGeneric: [
      { text: "Si", value: true},
      { text: "No", value: false},
    ],
    optionsContry: [
      { text: "USA", value: 2},
      { text: "Mexico", value: 1},
    ],
    isLoading: false,
    isEdit: false,
    configRol: null,
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.administrador?.pestana.users.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.users.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  async mounted() {
    try {
      await this.getUsers();
      await this.getRoles();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    validationDropdown() {
      if (
        this.configRol.administrador.pestana.users.acciones.crear &&
        this.selectedUser.length === 0
      ) {
        return true;
      } else if (
        this.configRol.administrador.pestana.users.acciones.editar &&
        this.selectedUser.length
      ) {
        return true;
      }
      return false;
    },
    validationName() {
      return this.validationInputName();
    },
    validationEmail() {
      return this.validationInputEmail();
    },
    isValid() {
      if (this.validationInputName() && this.validationInputEmail()) {
        if (this.selectedRol !== null && this.selectedStatus !== null && this.genericSelected !== null && this.countrySelected !== null) {
          return true;
        }
      }
      return false;
    },
    fieldsUsers() {
      return [
        { key: "actions", label: "" },
        {
          key: "nombre",
          label: this.$i18n.t("admin.users.name"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "email",
          label: this.$i18n.t("admin.users.email"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "roles",
          label: this.$i18n.t("admin.users.role"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "is_user_active",
          label: this.$i18n.t("admin.users.status"),
          sortable: true,
          sortDirection: "desc",
        },
      ];
    },
  },
  methods: {
    validationInputName() {
      return this.inputFullName.length > 5;
    },
    validationInputEmail() {
      return /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(this.inputEmail);
    },
    cancel() {
      this.$refs.dropdown.hide(true);
    },
    async handleCreateUser() {
      try {
        this.isLoading = true;
        await ApiRequest.post(`${API_ROOT}auth/registro`, {
          nombre: this.inputFullName,
          email: this.inputEmail,
          roles: [this.selectedRol],
          estado: this.selectedStatus,
          generico:this.genericSelected,
          pais: this.countrySelected
        });
        this.getUsers();
        AlertForm(this.$i18n.t("admin.users.userCreate"), "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.users.errorCreateUser"), ICON.ERROR);
      } finally {
        this.$refs.dropdown.hide(true);
        this.isLoading = false;
      }
    },
    async getUsers() {
      try {
        const data = await ApiRequest.get(`${API_ROOT}auth/usuarios`);
        this.itemsUsers = data.listado;
      } catch (err) {
        this.itemsUsers = [];
      }
    },
    async getRoles() {
      try {
        const data = await ApiRequest.get(`${API_ROOT}auth/roles`);
        let rolesOptions = data.roles.map((el) => ({
          text: el.name,
          value: el.name,
        }));
        this.optionsRol = rolesOptions;
      } catch (err) {
       AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async handleEditUser() {
      try {
        this.isLoading = true;
        await ApiRequest.put(`${API_ROOT}auth/usuario`, {
          nombre: this.inputFullName,
          email: this.inputEmail,
          roles: [this.selectedRol],
          estado: this.selectedStatus,
          generico:this.genericSelected,
          pais: this.countrySelected
        });
        this.getUsers();
        AlertForm(`${this.$i18n.t("admin.users.updateUser")} ${this.inputEmail}`, "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.$refs.dropdown.hide(true);
        this.isLoading = false;
      }
    },
    onRowSelectedUser(items) {
      this.selectedUser = items;
      if (items.length) {
        const [user] = items;
        this.inputFullName = user.nombre || "";
        this.inputEmail = user.email;
        this.selectedRol = user.roles[0] ? user.roles[0].name : "";
        this.selectedStatus = user.is_user_active;
        this.genericSelected = user.is_generico,
        this.countrySelected = user.pais,
        this.isEdit = true;
      } else {
        this.inputFullName = "";
        this.inputEmail = "";
        this.selectedRol = null;
        this.selectedStatus = null;
        this.genericSelected = null,
        this.countrySelected = null,
        this.isEdit = false;
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";
/** FORM USER */
.form-user {
  font-weight: bold;
}
#input-name.form-control,
#input-email.form-control {
  color: #333232;
  background: #e9e8e8c9;
  border: none;
}
/**menu dropdown */
#dropdown-form /deep/ .form-user {
  width: 400px;
}
#dropdown-form /deep/ .b-dropdown-text {
  color: #707070;
  font-weight: bolder;
}
#dropdown-form input.form-input-style,
#dropdown-form /deep/ .select {
  font-weight: bold;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #7070708a;
  margin: 15px 0;
}
#dropdown-form /deep/ .select {
  color: #707070c4;
}
#dropdown-form input.form-input-style::placeholder {
  color: #7070709d;
}
#dropdown-form input.form-input-style:focus {
  outline: none;
}
/**BUTTON STYLES */
.text-align-end {
  text-align: end;
}
#dropdown-form .buttons-dropdown {
  display: flex;
}
#dropdown-form .btn-blue-dark,
#dropdown-form .btn-blue-dark-outline {
  padding: 5px 10px;
  margin: 5px;
}
/**Table styles */
.tableFirst /deep/ .table-users {
  border-bottom: 1px solid rgb(160, 158, 158);
}
</style>