var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5", attrs: { role: "group" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4 align-items-center text-align-end" },
        [
          _c("b-col", { attrs: { cols: "5" } }),
          _c(
            "b-col",
            [
              _c(
                "b-input-group",
                { attrs: { size: "md" } },
                [
                  _c("b-form-input", {
                    staticClass: "custom-input",
                    attrs: {
                      id: "filter-input",
                      type: "search",
                      placeholder: this.$i18n.t("admin.users.placeholderSearch")
                    },
                    model: {
                      value: _vm.filterUsers,
                      callback: function($$v) {
                        _vm.filterUsers = $$v
                      },
                      expression: "filterUsers"
                    }
                  })
                ],
                1
              ),
              _c("b-icon", {
                staticClass: "input__search__icon",
                attrs: { icon: "search", "aria-hidden": "true", "flip-h": "" }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _vm.validationDropdown
                ? _c(
                    "b-dropdown",
                    {
                      ref: "dropdown",
                      staticClass: "m-2",
                      attrs: {
                        id: "dropdown-form",
                        "menu-class": "form-user",
                        right: "",
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "button btn-blue-dark ml-0",
                                    attrs: { variant: "outline-primary" }
                                  },
                                  [
                                    _vm.selectedUser.length
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.users.editUser")
                                            ) + " +"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.users.createUser")
                                            ) + " +"
                                          )
                                        ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3135375317
                      )
                    },
                    [
                      _c(
                        "b-dropdown-text",
                        { staticClass: "my-3 text-header-user" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "person-plus",
                              "aria-hidden": "true"
                            }
                          }),
                          _vm.selectedUser.length
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("admin.users.editUser"))
                                )
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("admin.users.newUser")))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-form",
                        [
                          _c("b-form-input", {
                            staticClass: "form-input-style",
                            attrs: {
                              id: "dropdown-form-name",
                              size: "sm",
                              placeholder: this.$i18n.t("admin.users.fullName"),
                              state: _vm.validationName
                            },
                            model: {
                              value: _vm.inputFullName,
                              callback: function($$v) {
                                _vm.inputFullName = $$v
                              },
                              expression: "inputFullName"
                            }
                          }),
                          _c("b-form-input", {
                            staticClass: "form-input-style",
                            attrs: {
                              id: "dropdown-form-email",
                              size: "sm",
                              placeholder: "email@example.com",
                              state: _vm.validationEmail,
                              type: "email",
                              disabled: _vm.isEdit
                            },
                            model: {
                              value: _vm.inputEmail,
                              callback: function($$v) {
                                _vm.inputEmail = $$v
                              },
                              expression: "inputEmail"
                            }
                          }),
                          _c("b-form-select", {
                            staticClass: "mt-3 select",
                            attrs: { options: _vm.optionsRol, size: "sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          staticClass: "m-3",
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("admin.users.role"))
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1330794958
                            ),
                            model: {
                              value: _vm.selectedRol,
                              callback: function($$v) {
                                _vm.selectedRol = $$v
                              },
                              expression: "selectedRol"
                            }
                          }),
                          _c("b-form-select", {
                            staticClass: "mt-3 select",
                            attrs: { options: _vm.optionsStatus, size: "sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("admin.users.status"))
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2644517469
                            ),
                            model: {
                              value: _vm.selectedStatus,
                              callback: function($$v) {
                                _vm.selectedStatus = $$v
                              },
                              expression: "selectedStatus"
                            }
                          }),
                          _c("b-form-select", {
                            staticClass: "mt-3 select",
                            attrs: { options: _vm.optionsGeneric, size: "sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.users.generic")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2534768952
                            ),
                            model: {
                              value: _vm.genericSelected,
                              callback: function($$v) {
                                _vm.genericSelected = $$v
                              },
                              expression: "genericSelected"
                            }
                          }),
                          _c("b-form-select", {
                            staticClass: "mt-3 select",
                            attrs: { options: _vm.optionsContry, size: "sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.users.country")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              334230465
                            ),
                            model: {
                              value: _vm.countrySelected,
                              callback: function($$v) {
                                _vm.countrySelected = $$v
                              },
                              expression: "countrySelected"
                            }
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "justify-content-end buttons-dropdown"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-blue-dark-outline ml-0",
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("admin.users.cancel")) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.selectedUser.length
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled: _vm.isLoading || !_vm.isValid
                                      },
                                      on: { click: _vm.handleEditUser }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.save")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled: _vm.isLoading || !_vm.isValid
                                      },
                                      on: { click: _vm.handleCreateUser }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.create")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.configRol.administrador.pestana.users.acciones.listar
        ? [
            _c("b-table", {
              staticClass: "tableFirst",
              attrs: {
                items: _vm.itemsUsers,
                fields: _vm.fieldsUsers,
                filter: _vm.filterUsers,
                "filter-included-fields": _vm.filterOn,
                stacked: "md",
                "show-empty": "",
                hover: "",
                "table-variant": "light",
                "sort-icon-left": "",
                "select-mode": "single",
                selectable: "",
                borderless: "",
                "tbody-tr-class": "table-users"
              },
              on: { "row-selected": _vm.onRowSelectedUser },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(nombre)",
                    fn: function(row) {
                      return [_c("b", [_vm._v(_vm._s(row.value))])]
                    }
                  },
                  {
                    key: "cell(roles)",
                    fn: function(row) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.value[0] && row.value[0].name) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(ref) {
                      var rowSelected = ref.rowSelected
                      return [
                        rowSelected
                          ? [
                              _c("div", [
                                _c(
                                  "span",
                                  { attrs: { "aria-hidden": "true" } },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "check-square",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Selected")
                                ])
                              ])
                            ]
                          : [
                              _c(
                                "span",
                                { attrs: { "aria-hidden": "true" } },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "square",
                                      "aria-hidden": "true"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Not Selected")
                              ])
                            ]
                      ]
                    }
                  },
                  {
                    key: "cell(is_user_active)",
                    fn: function(row) {
                      return [
                        row.value
                          ? _c("b", { staticClass: "text-success" }, [
                              _vm._v("Activo")
                            ])
                          : _c("b", { staticClass: "text-secondary" }, [
                              _vm._v("Inactivo")
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                3357410817
              )
            })
          ]
        : [
            _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
              _c("p", [
                _vm._v(
                  " " + _vm._s(_vm.$t("permissions.msgPermissionsUsers")) + " "
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }